#layout {
  #menu {
    ion-header {
      ion-toolbar {
        --background: #1a1e32;
        --color: #ecad01;
        --padding-top: 1.5rem;
        --padding-bottom: 1.5rem;
        --padding-start: 0;
        --padding-end: 0;
      }
    }

    #menu-content {
      background: #20243a;
      color: rgba(255, 255, 255, 0.5);
      padding-top: 1.62rem;
    }

    ion-footer {
      ion-toolbar {
        --background: #20243a;
        --color: rgba(255, 255, 255, 0.5);
        --padding-bottom: 1.5rem;
      }
    }
  }

  ion-item#nav-item {
    --background: transparent;
    --color: rgba(255, 255, 255, 0.5);
    --min-height: 2.5rem;
    opacity: 50%;
    &.router-link-active {
      --background: #353c63;
      opacity: 100%;
    }
    ion-label {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      color: var(--h-white);
    }
    fa-icon {
      // margin-inline-end: 0.5rem;
    }
  }

  ion-content::part(scroll) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

ion-popover.select-popover {
  // important is used here to over ride width applied by ionic directly using style attribute
  --width: auto !important;
}

ion-progress-bar.hes-progress {
  --background: transparent;
  --progress-background: var(--h-color-primary);
}

ion-backdrop {
  background: var(--ion-color-dark);
  opacity: 0.3;
}

ion-tab-bar {
  --ion-tab-bar-background: #fff;
  contain: unset;
}

ion-tab-button {
  position: relative; /* Ensure the parent is positioned */
  overflow: visible; /* Allow overflow to be visible */
}
ion-tab-button.tab-selected::after {
  display: block;
  content: "";
  height: 0.125rem;
  width: 3.5rem;
  background: var(--h-color-primary, #ecad01);
  top: -0.125rem;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}

ion-header {
  ion-toolbar {
    --background: #fff;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 2rem;
    --padding-end: 2rem;
    --min-height: 100%;
  }
}
