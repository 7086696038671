.xng-breadcrumb-root {
  margin: 0;
}

.xng-breadcrumb-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.xng-breadcrumb-item {
  list-style: none;
}

.xng-breadcrumb-trail {
  display: flex;
  align-items: center;
}

.xng-breadcrumb-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  transition: text-decoration 0.3s;
  cursor: pointer;
}

.xng-breadcrumb-link:hover {
  text-decoration: underline;
}

.xng-breadcrumb-link-disabled {
  pointer-events: none;
  cursor: disabled;
}

.xng-breadcrumb-separator {
  display: flex;
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
}
