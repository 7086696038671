<div
  *transloco="let t"
  class="Modal flex max-h-[90vh] w-full max-w-[500px] flex-col items-center justify-center gap-4 overflow-y-auto rounded-xl bg-white p-6 text-center shadow"
>
  <fa-icon
    class="mt-1 flex cursor-pointer text-[#D92D20] hover:text-[#0000008a]"
    [icon]="faCircleExclamation"
    size="xl"
  ></fa-icon>
  <div class="mb-4 mt-5 text-lg font-semibold text-[#101828]">
    {{
      blockedStatus === "INACTIVE"
        ? t("deactivation_deactivated_user.account_deactivated_title.txt")
        : t("deactivation_paused_user.logged_out_by_admin_msg.txt")
    }}
  </div>
  <div class="mb-8 text-sm font-normal text-[#667085]">
    {{
      blockedStatus === "INACTIVE"
        ? t("deactivation_deactivated_user.account_deactivated_msg.txt")
        : t("deactivation_paused_user.account_paused_msg.txt")
    }}
  </div>
  <div
    class="text-sm font-normal text-[#272525]"
    [innerHTML]="sanitizedMessage"
  ></div>
</div>
